import * as React from 'react';
import printimg from '../Content/Images/icons/iconPrinter.png';
import calendarimg from '../Content/Images/icons/iconCalendarAppt32.png';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import * as ConfirmationStore from '../store/Register';
import EventInfo from './EventInfo_Registrants';

type ConfirmationProps =
    ConfirmationStore.RegisterState &
    typeof ConfirmationStore.actionCreators &
    RouteComponentProps<{ cc: any }>;

export interface ListItems {
    itemValue: string;
    itemText: string;
}

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}
const getDateTime = (): string => {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours() % 12;
    var minute = now.getMinutes();

    var dateTime = month + '_' + day + '_' + year + '_' + (hour ? hour : 12) + ':' + minute;
    return dateTime;
}

export class Confirmation extends React.PureComponent<ConfirmationProps, any> {
    constructor(props: ConfirmationProps) {
        super(props);
        //var cCode = this.props.match.params["cc"];
        this.state = { EventRegistrantInfo: '', ddlState: [], display: 'none' }

        fetch('api/ListItems/State')
            .then(response => response.json() as Promise<Array<ListItems[]>>)
            .then(list => {
                this.setState({ ddlState: list });
            }).catch(error => {
                console.log(error);
            });

        var confirmationcode = this.props.match.params.cc; //this.props.match.url.split("/")[2];
        if (confirmationcode !== '' && confirmationcode !== undefined)
            this.handleConfirmationCode(confirmationcode);
        else
            this.handleConfirmationCode("");

        this.ShowPopUp = this.ShowPopUp.bind(this);
        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.SendGoogleCalendar = this.SendGoogleCalendar.bind(this);
        this.SendCalendar = this.SendCalendar.bind(this);
        this.SendCalendarEmail = this.SendCalendarEmail.bind(this);
    }

    handleConfirmationCode(code: any) {
        if (code === "")
            code = this.props.confirmationCode;
        const queryStr = serialize({ sConfirmatiomCode: code })
        const base_url = 'api/Registration/GetEventRegistrantInfo';
        const init = { method: 'GET', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.json())
            .then(responsedata => {
                this.props.setConfirmationCode(responsedata.ConfirmationCode);
                this.props.setEventID(responsedata.EventId);
                this.setState({ EventRegistrantInfo: responsedata });
            });
    }

    SendGoogleCalendar() {
        const queryStr = serialize({ sEventId: this.state.EventRegistrantInfo.EventId })
        const base_url = 'api/Registration/SendGoogleCalendar';
        const init = { method: 'POST', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.text())
            .then(responsedata => {
                window.open(responsedata, '_newtab');
                setTimeout(() => this.setState({ display: 'none' }), 1000);
            }).catch(error => {
                console.log(error);
            });
    }

    SendCalendar(option: any) {
        const queryStr = serialize({ sEventId: this.state.EventRegistrantInfo.EventId, sCalendarOptions: option })
        const base_url = 'api/Registration/SendOutlookCalendar';
        const init = { method: 'POST', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.blob())
            .then(blob => {
                let basename = option === '2' ? 'iCal' : 'Outlook';
                let filename = basename + '_' + getDateTime();
                filename = filename + '.ics';
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                a.remove();
                setTimeout(() => this.setState({ display: 'none' }), 1000);
            })
    }

    SendCalendarEmail() {
        const queryStr = serialize({ sConfirmatiomCode: this.state.EventRegistrantInfo.ConfirmationCode })
        const base_url = 'api/Registration/SendCalendarEmail';
        const init = { method: 'POST', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.text())
            .then(responsedata => {
                this.setState({ display: 'none' });
            }).catch(error => {
                console.log(error);
            });
    }

    ShowPopUp() {
        this.setState({
            display: 'block'
        });
    }
    ClosePopUp() {
        this.setState({
            display: 'none'
        });
    }

    public render() {
        let contents = this.renderCreateForm();

        return <div className="confirmationContents">
            {contents}
        </div>;
    }

    // Returns the HTML Form to the render() method.
    private renderCreateForm() {

        return (
            <div className="Confirmation-Container">
                <div style={{ padding: "10px", width: "100%", margin: "0 auto" }}>
                    <div>
                        <EventInfo />
                    </div>

                    <div className="" style={{ borderTop: "1px solid black" }}>

                        <div className="row">
                            <div className="receiptColumn receiptColumnLeft">
                                <div style={{ marginTop: "20px" }}>
                                    <h4 style={{ color: "black", fontWeight: "bold" }}>Status: Confirmed</h4>
                                    <div style={{ float: "left" }}>
                                        <label id="lblRecieptText" style={{ color: "black" }}>{ReactHtmlParser(this.state.EventRegistrantInfo.StatusInformation)}</label>
                                    </div>

                                </div>
                            </div>
                            <div id="divReceiptColumn" className="receiptColumn receiptColumnRight">
                                <div style={{ float: "left", textAlign: "center" }} >
                                    <table className="receiptButtonsColumn">
                                        <tr>
                                            <td>
                                                <img src={calendarimg} alt="Calendar" /><br />
                                                <a className="linkbutton" id="aAddToCalender" onClick={this.ShowPopUp}>Add to<br />
                                                    Calendar</a>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={printimg} alt="Print" /><br />
                                                <a className="linkbutton" href="javascript:window.print();">Print This<br />
                                                    Page</a>
                                            </td>

                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="statusForm">
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <div><strong style={{ color: "black" }}>Attendee Information</strong></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">First Name: </span><br></br>
                                    <input type="text" name="firstname" value={this.state.EventRegistrantInfo.FirstName} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Last Name: </span><br></br>
                                    <input type="text" name="lastname" value={this.state.EventRegistrantInfo.LastName} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Email: </span><br></br>
                                    <input type="text" name="email" value={this.state.EventRegistrantInfo.Email} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Phone Number: </span><br></br>
                                    <input type="text" name="phone" value={this.state.EventRegistrantInfo.Phone} className="Confirmation-fields" disabled />
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                <div className="ConfirmationPopup" id="divCalendarPopUp" style={{ display: this.state.display }}>
                    <div className="Confirm_Dialog">
                        <div className="Confirm_Dialog_Title">
                            Choose Your Calendar Receipt Method<a className="closebox" onClick={this.ClosePopUp}>X</a>
                        </div>
                        <div>
                            <div className="BtnRow"><a className="btn btn_small" onClick={this.SendGoogleCalendar}>Google Calendar</a></div>
                            <div className="BtnRow"><a className="btn btn_small" onClick={() => this.SendCalendar('2')}>iCal Calendar</a></div>
                            <div className="BtnRow"><a className="btn btn_small" onClick={() => this.SendCalendar('3')}>Outlook Calendar</a></div>
                            <div className="BtnRow"><a className="btn btn_small" onClick={this.SendCalendarEmail}>E-mail</a></div>
                        </div>
                    </div>
                </div>

            </div >)
    }
}
export default connect(
    (state: ApplicationState) => state.register,
    ConfirmationStore.actionCreators
)(Confirmation as any);
