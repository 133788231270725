import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Noven from '../Content/Images/Logo/NovenLogo.png';
import Dismiss from '../Content/Images/icons/icon_dismiss.png';
import { withRouter } from "react-router";



const HEADER_TITLE: any = {
    "home": "",
    "searchevents": "Event Search",
    "register": "Event Registration",
    "receipt": "Registration Confirmation",
    "confirmation": "Registration Confirmation"
}

class Header extends React.PureComponent<RouteComponentProps<{}>, any> {
    constructor(props: any) {
        super(props);
        this.goHome = this.goHome.bind(this);
        this.handleDismissClick = this.handleDismissClick.bind(this);        
    }

    componentDidMount() {
        var divCookie = document.getElementById('divBannerCookieNotification')
        if (document.cookie.indexOf('cookie_notification') > -1) {
            var cookie = document.cookie.substring(document.cookie.indexOf('cookie_notification'));
            var dismissed = cookie.indexOf(';') > -1 ? cookie.substring(cookie.indexOf('=') + 1, cookie.indexOf(';')) : cookie.substring(cookie.indexOf('=') + 1);

            // dismissed = 'Always Show'; // Comment this line to function COrrectly 
            
            if (dismissed !== 'true') {
                if (divCookie != null)
                    divCookie.style.display = 'none';
            }
        } else {
            if (divCookie != null)
                divCookie.style.display = 'none';
        }

        //Add view port to handle mobile resolution
        var viewPortTag = document.createElement('meta');
        viewPortTag.id = "viewport";
        viewPortTag.name = "viewport";
        viewPortTag.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
        document.getElementsByTagName('head')[0].appendChild(viewPortTag);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    handleDismissClick() {
        var divCookie = document.getElementById('divBannerCookieNotification');
        document.cookie = 'cookie_notification=true; path=/';
        if (divCookie != null)
            divCookie.style.display = 'none';
        
    }

    private goHome(e: any) {
        e.preventDefault();
        this.props.history.push("/");
    }

    render() {        
        let pathname = this.props.location.pathname;
        let pathnameArr = pathname.split("/");
        let headerTitle = pathnameArr[1] ? HEADER_TITLE[pathnameArr[1]] : "";
        //if (headerTitle == undefined || headerTitle == "")
        if (headerTitle == undefined)
            headerTitle = "Event Registration";

        return (
            <div className="divHeader">
                <div id="divBannerCookieNotification" style={{ display: "none" }}>
                    <div className="pure-g" id="cookie-container">
                        <div className="pure-u-22-24">
                            This website uses cookies to improve user experience. By continuing to use this website, you consent to our use of these cookies. To learn more
                about how we use and manage cookies, see our <a href="#" target="_blank">Privacy Policy</a> and <a href="#" target="_blank">Cookie Policy</a>.
            </div>
                        <div className="pure-u-2-24">
                            <img src={Dismiss} className="pure-img" id="dismiss-notification" alt="Dismiss cookie notification" onClick={this.handleDismissClick} />
                        </div>
                    </div>

                </div>
                <div id="divBannerCovid19Notification" style={{ display: "none" }}>
                    <div className="pure-g" id="covid19-container">
                        <div className="covid-text">
                            <div className="covid-text-1">
                                Due to COVID-19, live events are currently virtual, not in-person.
                            </div>
                        </div>
                    </div>
                </div>
                {headerTitle ?
                    <header >
                        <div style={{ backgroundColor: "#003468", height: "34px", width: "100%" }}>
                            <div className="headerTitle">
                                {headerTitle}
                            </div>
                            <div className="headerHome">
                                <a style={{ color: "#FFF", display: "none", textDecoration: "none" }} href="#" onClick={this.goHome}>Home</a>
                            </div>
                        </div>
                        <div>
                            <div style={{ padding: "10px 10px 10px 10px", borderBottom: "5px solid #fdba31", marginTop: "10px" }}>
                                <div id="header-title" style={{ height: "30px" }}>
                                    <div style={{ float: "left" }}>
                                        <img src={Noven} alt="Noven" />
                                    </div>
                                    <div style={{ float: "right", position: "relative" }}>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </header> : <header></header>
                }
            </div >
        );
    }
}

export default withRouter(Header);