import * as React from 'react';
import Noven from '../Content/Images/Logo/NovenLogo.png';


export class Home extends React.PureComponent<any, any> {

    componentDidMount() {
        debugger;
        var footer = document.getElementsByClassName("footer");
        var divHeader = document.getElementsByClassName("divHeader");
        if (footer.length > 0)
            footer[0].parentNode?.removeChild(footer[0]);
        if (divHeader.length > 0)
            divHeader[0].parentNode?.removeChild(divHeader[0]);
    }

    render() {
        return (
                <div style={{ lineHeight: "1.6em", margin: "0 auto", maxWidth: "100%", padding: " 20px 0px 0px 20px", textAlign: "center", wordWrap: "break-word" }}>
                <h1 style={{ fontSize: "50px", fontWeight: "bolder", marginTop: "50px" }}> Error 404 - Not Found</h1>
                <h1 style={{ fontSize: "16px", fontWeight: "lighter", marginTop: "20px" }}> We're sorry, the page you requested is not here.</h1>
                </div>
        );
    }

};
export default (Home);