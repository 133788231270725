import * as React from 'react';
import logo from '../Content/Images/Logo/Capri_logowS4C.png';
import hcalogo from '../Content/Images/Logo/HCA_logo.jpg'
export class Footer extends React.PureComponent<any, any> {
    //static displayName = Footer.name;       

    render() {
        return (
            <div>
                <footer className="footer">
                    <div className="row">
                        <div className="column">                            
                        </div>
                        <div className="column">
                            
                            <div>
                                <div style={{ textAlign: "center", left: "10px", fontSize: "15px", margin:"0 auto" }}>
                                        <a href="https://www.secuado.com/terms-of-use/?_sp=afc41dd6-6412-4147-8714-336cd757d6f0.1610654123713" target="_blank" style={{ color:"#ffffff" }}>Terms of Use</a>                                        
                                &nbsp;|&nbsp;
                        <a href="https://www.secuado.com/privacy/?_sp=afc41dd6-6412-4147-8714-336cd757d6f0.1610653958165" target="_blank" style={{ color: "#ffffff" }}>Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                </div>
                                    <br></br>
                                        <div style={{ border: "2px solid #d3d3d3", color: "#ffffff", width: "280px", padding: "5px 10px", fontSize: "13px", display:"none" }}>
                                            These programs are intended to provide information about the first-ever oral prophylactic therapy for people living with hereditary angioedema (HAE).                                    </div>
            </div>
                        </div>
                        <div className="column">
                            <table className="footer_managedby">
                                <tr>
                                    <td>
                                        <span style={{ float: "left", fontSize: "12px", color: "#ffffff" }}>Managed by:</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src={hcalogo} alt="HCAlogo"  className="transcendLogo"  />
                                            <br></br>                                            
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </footer>
            </div >
        );
    }
}